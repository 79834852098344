import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '4fr 10fr',
    gridGap: '24px'
  },
  dragDropArea: {
    height: '278px',
    background: 'linear-gradient(180deg, #F0F9FF 0%, #DDF1FF 100%)',
    border: '1px dashed #1979FF',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    marginTop: '15px'
  },
  dragDropLabel: {
    fontSize: '1em',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: theme.palette.grey.A400,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em 0'
  },
  dragDropLabelBold: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  browseFilesLabel: {
    fontWeight: 'bold',
    fontSize: '1em',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.1px',
    textDecorationLine: 'underline',
    color: theme.palette.primary.dark,
    cursor: 'pointer'
  },
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 0.8em'
  },
  formSecondaryAction: {
    width: '10.375em',
    borderRadius: '5px'
  },
  formPrimaryAction: {
    width: '10.375em',
    marginRight: '0.8em',
    borderRadius: '5px'
  },
  field: {
    height: 45,
    marginTop: 6
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  documentsDropDown: {
    paddingTop: 12,
    width: 220
  },
  actionsWrapper: {
    marginLeft: 'calc(4em + 10px)'
  }
}));
