import { Axios } from 'core';
import { DeleteCompanyRequestType, IndustryType } from '../types';
import { CompanyType } from 'store';
import {
  ADD_COMPANY_ENDPOINT,
  DELETE_COMPANY_ENDPOINT,
  EDIT_COMPANY_ENDPOINT,
  GET_INDUSTRIES_ENDPOINT
} from './endpoints';
import { OptionType } from 'ui-kit';
import { isEmpty } from 'lodash';
import { CIK, PRIVATE } from '../constants';

export async function deleteCompany(
  deleteCompanyRequest: DeleteCompanyRequestType
): Promise<CompanyType> {
  const reqBody = {
    company_id: deleteCompanyRequest.companyIds
  };
  return await Axios.delete(DELETE_COMPANY_ENDPOINT, { data: reqBody });
}

export async function addCompany(companyType: CompanyType): Promise<CompanyType> {
  const companyIndustry = companyType.industry as OptionType;
  const newIdentifiers = companyType?.identifiers.map((identifier) => ({
    identifier_type: identifier.identifier_type,
    identifier_value: identifier.identifier_value
  }));
  if (companyType.is_private) {
    newIdentifiers.push({ identifier_type: PRIVATE, identifier_value: companyType.ticker });
  }

  const reqBody = {
    cik: companyType.cik,
    name: companyType.name,
    sector: companyType.sector,
    ticker: companyType.ticker,
    industry_fk: companyIndustry?.value,
    identifiers: newIdentifiers,
    is_private: Boolean(companyType.is_private),
    active: Boolean(companyType.active),
    emails:
      Boolean(companyType.is_private) && !isEmpty(companyType.usersEmailsToAccessPrivateCompany)
        ? companyType.usersEmailsToAccessPrivateCompany.split(',')
        : [],
    is_abridged: Boolean(companyType.is_abridged),
    reports_hy: Boolean(companyType.reports_hy),
    historical_hy: Boolean(companyType.reports_hy),
    is_us_company: Boolean(companyType.is_us_company)
  };
  return await Axios.post(ADD_COMPANY_ENDPOINT, reqBody);
}

export async function editCompany(payload: Partial<CompanyType>): Promise<CompanyType> {
  return await Axios.put(EDIT_COMPANY_ENDPOINT, payload);
}

export async function loadIndustries(): Promise<IndustryType[]> {
  return await Axios.get(GET_INDUSTRIES_ENDPOINT);
}
