import React from 'react';
import { AsyncComboBox, OptionType } from 'ui-kit';
import { debounce } from '@material-ui/core';
import { OptionsType } from 'react-select';
import { isEmpty } from 'lodash';
import { GetTickersType } from './types';
import { getTickers } from './service';
import { CompanyType, FormikType } from '../shared/types';

interface TickerDropdownProps {
  formik?: FormikType;
  selectedValue?: OptionType | OptionType[] | undefined;
  onValueChange?: (field: string, newValue: OptionType | OptionType[]) => void;
  valueFieldKey?: 'cik' | 'id' | 'capiqcompanyid';
  filterOptions?: (company: CompanyType) => boolean;
  isMulti?: boolean;
  withLiveModels?: boolean;
}

const TickerDropdown: React.FC<TickerDropdownProps> = ({
  selectedValue,
  formik,
  onValueChange,
  valueFieldKey = 'id',
  filterOptions = () => true,
  isMulti = false,
  withLiveModels
}) => {
  const promiseOptions = debounce(
    async (inputValue: string, callback: (options: ReadonlyArray<OptionType>) => void) => {
      const response: GetTickersType = await getTickers(inputValue, { withLiveModels });
      const options = response.data.entities.filter(filterOptions).map((entity) => ({
        label: entity.ticker as string,
        value: ['cik', 'capiqcompanyid'].includes(valueFieldKey)
          ? entity.identifiers.find(
              (identifier) => identifier.identifier_type.toLowerCase() === valueFieldKey
            )?.identifier_value ?? ''
          : entity.id?.toString() ?? '',
        id: entity.id
      }));
      callback(options);
    },
    300
  );

  const loadOptions = (
    inputValue: string,
    callback: (options: ReadonlyArray<OptionType>) => void
  ) => {
    if (!isEmpty(inputValue)) {
      promiseOptions(inputValue, callback);
    }
    return undefined;
  };

  const onChange = (
    field: string,
    value: OptionType | OptionsType<OptionType> | string | undefined | null
  ) => {
    if (formik) {
      formik.setFieldValue(field, value);
    } else if (onValueChange) {
      onValueChange(field, value as OptionType);
    }
  };

  const getValue = () => {
    if (formik && formik.values.ticker) {
      return formik.values.ticker as OptionType;
    } else {
      return selectedValue;
    }
  };

  return (
    <AsyncComboBox
      name="ticker"
      id="ticker"
      placeholder="Select Ticker"
      value={getValue()}
      setFieldValue={onChange}
      loadOptions={loadOptions}
      isMulti={isMulti}
      error={Boolean(formik?.errors.ticker && formik?.touched.ticker)}
      helperText={formik?.touched.ticker && formik?.errors.ticker ? 'Ticker is required' : ''}
    />
  );
};

export default TickerDropdown;
