import React from 'react';
import { GridColumns, GridRowData } from '@material-ui/data-grid';
import TableRowActions from 'store/src/TableRowActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Priority from '../Priority';

export const getCompaniesColDef: (
  onDeleteRow: (row: GridRowData) => void,
  onEditRow: (row: GridRowData) => void,
  canDelete: boolean,
  canEdit: boolean
) => GridColumns = (onEditRow, onDeleteRow, canDelete, canEdit) => [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'Name', width: 150, resizable: true },
  {
    field: 'ticker',
    headerName: 'Ticker',
    width: 150,
    resizable: true
  },
  {
    field: 'industry_name',
    headerName: 'Industry Name',
    width: 210,
    resizable: true,
    renderCell: (params) => params.row.industry?.name
  },
  {
    field: 'industry_sector',
    headerName: 'Sector',
    width: 210,
    resizable: true,
    renderCell: (params) => params.row.industry?.sector?.name
  },
  {
    field: 'active',
    headerName: 'is Active',
    width: 150,
    type: 'boolean',
    renderCell: (params) => (params.row.active ? <CheckCircleIcon color="secondary" /> : '')
  },
  {
    field: 'is_private',
    headerName: 'is Private',
    width: 150,
    type: 'boolean',
    renderCell: (params) => (params.row.is_private ? <CheckCircleIcon color="secondary" /> : '')
  },
  {
    field: 'is_abridged',
    headerName: 'is Abridged',
    type: 'boolean',
    width: 150,
    renderCell: (params) => (params.row.is_abridged ? <CheckCircleIcon color="secondary" /> : '')
  },
  {
    field: 'priority',
    headerName: 'Priority',
    width: 150,
    renderCell: (params) => (params.row.priority ? <Priority priority={params.row.priority} /> : '')
  },
  {
    field: 'is_us_company',
    headerName: 'Is Us Company',
    width: 150,
    type: 'boolean'
  },
  {
    field: 'historical_hy',
    headerName: 'Historical HY',
    width: 150,
    type: 'boolean'
  },
  {
    field: '',
    headerName: 'Action ',
    renderCell: (params) => (
      <TableRowActions
        onEditRow={() => onEditRow(params.row)}
        onDeleteRow={() => onDeleteRow(params.row)}
        canDelete={canDelete}
        canEdit={canEdit}
      />
    ),
    width: 200,
    filterable: false
  }
];

export const CapIQCompanyTicker = 'CapIQCompanyTicker';
export const CIK = 'CIK';
export const ISIN = 'ISIN';
export const PRIVATE = 'PRIVATE';

export const EMPTY_IDENTIFIER_VALUE = -1;
export const identifierTypesOptions = [
  { label: CIK, value: CIK },
  { label: ISIN, value: ISIN },
  { label: 'CUSIP', value: 'CUSIP' },
  { label: 'SEDOL', value: 'SEDOL' },
  { label: 'FIGI', value: 'FIGI' },
  { label: 'PRIVATE', value: 'PRIVATE' },
  { label: 'CompanyHouseId', value: 'CompanyHouseId' },
  { label: 'CapIQCompanyId', value: 'CapIQCompanyId' },
  { label: CapIQCompanyTicker, value: CapIQCompanyTicker }
];

export const TICKER_PRIORITY_MAP: { [key: string]: string } = {
  '0': 'Critical',
  '1': 'Highest',
  '3': 'Medium',
  '4': 'Low'
};
