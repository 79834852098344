import React, { useEffect, useState } from 'react';
import { Dialog, OptionType } from 'ui-kit';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useMixpanel } from '../../../mixpanel/useMixpanel';
import { isEmpty, isNil, groupBy } from 'lodash';
import CompanyIdentifierInput from '../UploadViaLink/CompanyIdentifierInput';
import { useAction } from '../hooks/useAction';
import { useSelector } from 'react-redux';
import { selectIsLoading, selectSelectedCompany } from '../state/selectors';
import { notify, PeriodInput } from 'store';
import useStyles from '../UploadFile/styles';
import { getDocuments, runAutoTaggerTask } from '../services';
import { errorMsgResolver } from '../utils';
import { selectUser } from '../../Home/state/selectors';
import Select from 'react-select';
import { GroupedOptionTypes } from 'ui-kit/src/components/ComboBox/types';

interface AutoTaggerDocumentProps {
  isOpen: boolean;
  handleClose: () => void;
}

const AutoTaggerDocument: React.FC<AutoTaggerDocumentProps> = ({ isOpen, handleClose }) => {
  const myMixpanel = useMixpanel();
  const { resetLinksFormAction } = useAction();
  const classes = useStyles();
  const user = useSelector(selectUser);
  const isCompanyLoading = useSelector(selectIsLoading);
  const [isAutoTaggerTaskLoading, setSetAutoTaggerTaskLoading] = useState<boolean>(false);
  const selectedCompany = useSelector(selectSelectedCompany);
  const [period, setPeriod] = useState<string>('');
  const [selectedDocumentOption, setSelectedDocumentOption] = useState<OptionType | null>(null);
  const [companyDocumentOptions, setCompanyDocumentOptions] = useState<GroupedOptionTypes[]>([]);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState<boolean>(false);

  const beforeClose = () => {
    resetLinksFormAction();
    handleClose();
  };

  const getDocumentOptions = async () => {
    setIsDocumentsLoading(true);
    const documents = await getDocuments(selectedCompany.id, period);
    const documentOptions: GroupedOptionTypes[] = [];
    const documentsByFilingType = groupBy(documents, 'filingType');
    Object.keys(documentsByFilingType)
      .sort()
      .forEach((filingType) => {
        const docFilingTypes = documentsByFilingType[filingType];
        documentOptions.push({
          label: filingType,
          options: docFilingTypes.map((doc) => ({ label: doc.documentType, value: `${doc.id}` }))
        });
      });

    setCompanyDocumentOptions(documentOptions);
    setIsDocumentsLoading(false);
  };

  useEffect(() => {
    if (isOpen && !isEmpty(selectedCompany?.ticker) && !isEmpty(period)) {
      getDocumentOptions();
    } else {
      setCompanyDocumentOptions([]);
    }
  }, [selectedCompany, period, isOpen]);

  const submitRunAutoTagger = async () => {
    if (selectedDocumentOption === null) {
      return;
    }
    setSetAutoTaggerTaskLoading(true);
    try {
      const documentId = selectedDocumentOption.value;
      await runAutoTaggerTask(parseInt(documentId));
      myMixpanel?.track('goldengate:run_autotagger', {
        userEmail: user?.email,
        userId: user?.id,
        cik: selectedCompany?.cik,
        companyId: selectedCompany?.id,
        ticker: selectedCompany?.ticker,
        documentId
      });
      notify({
        message: 'AutoTagger Running Successfully',
        severity: 'success',
        open: true
      });
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setSetAutoTaggerTaskLoading(false);
    }
  };

  const dialogActionContent = (
    <>
      <DialogActions>
        <Box className={classes.formActions}>
          <Box>
            <Button
              className={classes.formPrimaryAction}
              variant="outlined"
              onClick={beforeClose}
              color="primary"
            >
              Close
            </Button>
            <Button
              className={classes.formPrimaryAction}
              variant="contained"
              onClick={submitRunAutoTagger}
              color="primary"
              disabled={
                isAutoTaggerTaskLoading ||
                isEmpty(selectedCompany?.cik) ||
                isEmpty(period) ||
                isNil(selectedDocumentOption)
              }
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      handleClose={beforeClose}
      isOpen={isOpen}
      hideDialogActions={true}
      isLoading={isAutoTaggerTaskLoading || isCompanyLoading || isDocumentsLoading}
      title="Run AutoTagger For Document"
      width="md"
      minHeight="450px"
      customDialogActions={dialogActionContent}
    >
      <Box className={classes.inputContainer}>
        <CompanyIdentifierInput identifierType={'cik'} />
        <Box className={classes.actionsWrapper}>
          <PeriodInput
            onPeriodChange={(newValue) => setPeriod(newValue)}
            value={period}
            label={'Period'}
          />

          {!isEmpty(selectedCompany) && !isEmpty(period) && !isEmpty(companyDocumentOptions) && (
            <Select
              className={classes.documentsDropDown}
              options={companyDocumentOptions}
              value={selectedDocumentOption}
              onChange={(newOption) => setSelectedDocumentOption(newOption)}
              isSearchable={false}
              placeholder={'Select Document'}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AutoTaggerDocument;
